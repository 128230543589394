import React, { useState, useEffect } from "react";
import { NavigationInner } from "./components/NavigationInner";
import { NavigationJumbo } from "./components/navigationJumbo";
import { ApplicationForm } from "./components/ApplicationForm";
import AppFormJsonData from "./data/application-form.json";
// import SmoothScroll from "smooth-scroll";


// export const scroll = new SmoothScroll('a[href*="#"]', {
//   speed: 1000,
//   speedAsDuration: true,
// });

const App = () => {
  const [applicationFormData, setApplicationFormData] = useState({});
  useEffect(() => {
    setApplicationFormData(AppFormJsonData);
  }, []);

  return (
    <div>
      <NavigationJumbo />


      <ApplicationForm data={applicationFormData.ApplicationForm} />
    </div>
  );
};

export default App;
