import React from "react";
const footerStyle = {
  fontFamily: '"Raleway", sans-serif',
  fontSize: '20px',
  fontWeight: 450,
};

export const Footer = (props) => {
  return (
    <div>
      <div class="container">
        
        <footer class="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 border-top" style={footerStyle}>
          <div class="col mb-3">
            <a href="/" class="d-flex align-items-center mb-3 link-dark text-decoration-none">
              Home
            </a>

          </div>

          <div class="col mb-3">

          </div>

          <div class="col mb-3">
            <h5></h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2"><a href="/apply" class="nav-link p-0 text-muted">Apply Now</a></li>
              <li class="nav-item mb-2"><a href="/home#services" class="nav-link p-0 text-muted">Who Can Apply</a></li>

            </ul>
          </div>

          <div class="col mb-3">
            <h5></h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2"><a href="/home#about" class="nav-link p-0 text-muted">About Us</a></li>
              <li class="nav-item mb-2"><a href="/home#partnerships" class="nav-link p-0 text-muted">Partners</a></li>
              <li class="nav-item mb-2"><a href="/home#portfolio" class="nav-link p-0 text-muted">Gallery</a></li>
              <li class="nav-item mb-2"><a href="/home#mission" class="nav-link p-0 text-muted">Mission</a></li>
            </ul>
          </div>

          <div class="col mb-3">
            <h5></h5>
            <ul class="nav flex-column">
              <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Contact Us</a></li>
              Contact info: 
              +2348125529621, 
              +2348113325455, 
              +19092978491
              <a href="https://www.instagram.com/omoze_nursing_educator?igsh=cjg5MXJrcGg5czdz&utm_source=qr">Instagram</a>

            </ul>
          </div>
        </footer>
      </div>

      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 OmozeONE
          </p>
        </div>
      </div>

    </div>
  );
}
