import React from "react";

export const WhyChooseUs = (props) => {
  return (
    <div id="about" className="custom-bg-col-div">
      <div className="container">
        <div className="row sub-section-title">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>Why Choose Us?</h2>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <p className="" key={`${d}-${i}`}>{d}</p>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <div>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <p className="h4" key={`${d}-${i}`}> {d}</p>
                        ))
                      : "loading"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};
