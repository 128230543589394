import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// import { Navigate } from 'react-router-dom';
import  Home  from "./Home"
import  HomeJumbo  from "./HomeJumbo"
import  Login  from "./Login"
import  Apply  from "./apply"
// import RedirectTo from './components/RedirectTo'; 




const App = () => {


  return (
    <Router>
      <Routes>
        {/* <Route path='/signup' element={<SignUp />} />
        <Route path='/health-login' element={<Login />} /> */}
        <Route path='/' element={<HomeJumbo />} />
        <Route path='/home' element={<HomeJumbo />} />
        <Route path='/old' element={<Home />} />
        <Route path='/apply' element={<Apply />} />
        {/* <Route path='/apply' element={<RedirectTo url="https://backend.omozeone.com" />} /> */}


        <Route path='/login' element={<Login />} />
        
      </Routes>
    </Router>
  );
};

export default App;
