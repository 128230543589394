import React from "react";

export const Mission = (props) => {
  return (
    <div id="mission" className="text-center custom-bg-col-div-2">
        <div  className="container">
          <div className="section-title">
            <div className="col-md-8 col-md-offset-2 section-title">
              <h2>Mission Statement</h2>
              <p>
              Our mission is to develop highly competent, compassionate, and culturally aware nursing professionals
                who are committed to excellence in patient care and advocacy. Through our accelerated program, we
                aim to empower students to become leaders in the healthcare field, equipped with the skills and
                knowledge to make a positive impact in their communities.
              </p>
            </div>

          </div>
        </div>
    </div>
  );
};
