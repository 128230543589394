import React, { useState } from 'react';
import { Link } from 'react-router-dom';




const navbarBrandStyle = {
  display: 'block',
  width: '400px', // Adjust the width as needed
  height: '80px',
  padding: '0px',
  background: 'url(/img/logo.png) left center no-repeat', 
  backgroundSize: 'cover', // Adjust the size to fit the element
  textAlign: 'center',
  position: 'relative',
  fontFamily: '"Raleway", sans-serif',
  fontSize: '24px',
  fontWeight: 0,
  color: '#333',
  textTransform: 'uppercase',
};

const navbarDropdownStyle = {
  padding: '10px',
  fontFamily: '"Raleway", sans-serif',
  fontSize: '18px',
  fontWeight: 450,
  color: '#fff',
  textTransform: 'uppercase',
  backgroundColor: 'rgba(13, 110, 253, 0.95)', //blue

};
const navbarStyle = {
    padding: '0px',
    fontFamily: '"Raleway", sans-serif',
    fontSize: '18px',
    fontWeight: 550,
    // color: '#333',
    textTransform: 'uppercase',
    // backgroundColor: 'rgba(227, 242, 253, 0.86)',
    backgroundColor: 'rgba(13, 110, 253, 0.95)', //blue
    // backgroundColor: 'rgba(220, 53, 69, 0.95)', // Reddish color
    marginBottom: '100px' 

  };
  const navbarBrandMatt = {
    // backgroundColor: 'rgba(255, 255, 255, 0.85)',
    width: '600px',
  };
  const brandStyle = {
    navbarRight: {
      position: 'absolute',
      right: 0,
    },
    relative: {
      position: 'relative',
    },
    mediaQueries: {
      '@media only screen and (max-width: 768px)': {
        navbarBrand: {
          maxWidth: '100px',
        },
        navbarToggler: {
          right: 0,
          position: 'absolute',
          margin: '10px',
        },
      },
    },
  };
  
export const NavigationJumbo = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
      setIsMenuOpen(false);
  };
  const handleNavClick = (event) => {
    //  event.preventDefault();
    closeMenu();
  };
  return (
<>


<nav className="navbar navbar-expand-lg navbar-dark fixed-top p-2" style={navbarStyle}>
  <div className="container-fluid"> {/* Changed from 'container px-100' to 'container-fluid' to allow full width */}
    <a className="navbar-brand" href="/"  style={navbarBrandMatt}>
      <img className="brand-logo" src="/img/omoze_logo_redesign_red_text_300.png" alt="logo" /> 
    </a>
    <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={isMenuOpen} aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}  id="navbarSupportedContent">
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 p-2">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="/">Home</a>
        </li>

        <li className="nav-item">
          <a href="/home#about" className="nav-link"  onClick={handleNavClick} >About Us</a>
        </li>
        {/* <li className="">
          <a href="/home#services" className="nav-link" data-toggle="collapse" data-target=".in">Admission</a>
        </li> */}
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="admissionDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Admission
          </a>
          <ul className="dropdown-menu" aria-labelledby="admissionDropdown" style={navbarDropdownStyle}>
            <li><a className="dropdown-item" href="/home#services" style={navbarDropdownStyle}>Who Can Apply</a></li>
            <li><a className="dropdown-item" href="/apply">Apply Now</a></li>
          </ul>
        </li>
        <li className="nav-item">
          <a href="/home#portfolio" className="nav-link">Gallery</a>
        </li>
        <li className="nav-item">
          <a href="/home#mission" className="nav-link">Mission</a>
        </li>
        <li className="nav-item">
          <a href="/home#partnerships" className="nav-link">Partners</a>
        </li>
        <li className="nav-item">
          <a href="/home#contact" className="nav-link">Contact</a>
        </li>
      </ul>
    </div>

  </div>
</nav>

</>

  );
};
