import React from 'react';

const LoginComponent = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-purple-600">Login</h2>
        <form>
          <div className="mb-4">
            <label className="block text-gray-700">Email Address</label>
            <input
              type="email"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-purple-500"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Password</label>
            <input
              type="password"
              className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-purple-500"
              required
            />
          </div>
          <div className="mb-4 flex items-center">
            <input type="checkbox" id="staySignedIn" className="mr-2" />
            <label htmlFor="staySignedIn" className="text-gray-700">Stay signed in for a week</label>
          </div>
          <div className="flex justify-between items-center">
            <button
              type="submit"
              className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700"
            >
              Continue
            </button>
            <button
              type="button"
              className="text-purple-600 hover:underline"
            >
              Return to login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginComponent;
