import React from "react";

export const Partnerships = (props) => {
  return (
    <div id="partnerships" className="mt-10">
      <div className="container">
        <div className="section-title text-center">
        <h2>PARTNERSHIP AND AFFILIATION</h2>
        <p>ONE is open to collaborating with accredited Universities across Africa to expand the opportunity we
            offer.
            However, our agency is currently affiliated with one university to provide efficient learning scope to
            our students. Our curriculum is well detailed and meets the standard of Nursing Councils across the
            globe.
        </p>
        <h3> ISSIC UNIVERSITY BENIN</h3>
        <p>
         
              Derriere Mosque Seyive, PK11
              Cotonou, Benin Repulic

        </p>




        </div>

      </div>
    </div>
  );
};
