import React from "react";


const navbarBrandStyle = {
  display: 'block',
  width: '400px', // Adjust the width as needed
  height: '80px',
  padding: '0px',
  background: 'url(/img/logo.png) center center no-repeat', // Add 'no-repeat' and 'center center'
  backgroundSize: 'cover', // Adjust the size to fit the element
  textAlign: 'center',
  position: 'relative',
  fontFamily: '"Raleway", sans-serif',
  fontSize: '3px',
  fontWeight: 0,
  color: '#333',
  textTransform: 'uppercase',
};

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">


        <div className="navbar-header">
          <a className="navbar-brand page-scroll" href="/" style={navbarBrandStyle}>
            Logo
            {/* <img src="img/logo.png" alt="Brand Logo" className="brand-logo desktop-logo" />
            <img src="img/logo_small.png" alt="Brand Logo" className="brand-logo mobile-logo" />  */}
          </a>{" "}
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>

        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1">



          <ul className="nav navbar-nav navbar-center">
            <li>
              <a href="#features" className="page-scroll" data-toggle="collapse" data-target=".in">
                Overview
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll" data-toggle="collapse" data-target=".in">
                Curriculum 
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll" data-toggle="collapse" data-target=".in">
                Admission 
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll" data-toggle="collapse" data-target=".in">
                Gallery
              </a>
            </li>
            <li>
              <a href="#mission" className="page-scroll" data-toggle="collapse" data-target=".in">
                Mission
              </a>
            </li>
            <li>
              <a href="#partnerships" className="page-scroll" data-toggle="collapse" data-target=".in">
                Partners
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll" data-toggle="collapse" data-target=".in">
                Contact
              </a>
            </li>
          </ul>
        </div>

    </nav>
  );
};
