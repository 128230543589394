import React, { useState } from "react";
import { ClipLoader } from 'react-spinners';


export const ApplicationForm = (props) => {
  const [formData, setFormData] = useState({
    firstName: 'eee',
    lastName: 'PPP',
    email: 'admin@admin.com',
    phone: '090',
    dob: '2024-12-12',
    address: '',
    city: '',
    state: '',
    zip: '',
    previousEducation: '',
    gpa: '0.5',
    statementOfPurpose: '',
    nextOfKin: '',
    transcript: null,
    passportData: null,
    passportPhoto: null,
  });


  const [alertState, setAlert] = useState({ visible: false, message: 'Default', type: 'danger' });
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [uploadProgress, setUploadProgress] = useState({
    transcript: 0,
    passportData: 0,
    passportPhoto: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      setFormData((prevState) => ({ ...prevState, [name]: files[0] }));
    }
  };

  const handleFileUpload = async (file, name) => {
    const formData = new FormData();
    formData.append(name, file);

    const uploadUrl = `${process.env.REACT_APP_BACKEND_API_URL}/apply/submit/upload/${name}`; //process.env.REACT_APP_BACKEND_API_URL + `/upload/${name}`;
    console.log(uploadUrl); 
    try {
      const response = await fetch(uploadUrl, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (e) => {
          if (e.lengthComputable) {
            setUploadProgress((prevState) => ({
              ...prevState,
              [name]: Math.round((e.loaded / e.total) * 100),
            }));
          }
        },
      });
      return response.ok;
    } catch (error) {
      console.error(`Error uploading ${name}:`, error);
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const { firstName, lastName, email, phone,dob, address, city, state, zip, previousEducation, gpa, statementOfPurpose, nextOfKin, transcript, passportData, passportPhoto } = formData;

    if (!firstName || !lastName || !email){ // || !phone || !gpa || !statementOfPurpose || !nextOfKin || !transcript || !passportData || !passportPhoto) {
      setAlert({
        visible: true,
        message: "Please fill out all required fields and upload all necessary files.",
        type: "danger"
      });
      setLoading(false);
      return;
    }

    // Upload files
    const uploadTranscript = await handleFileUpload(transcript, "transcript");
    const uploadPassportData = await handleFileUpload(passportData, "passportData");
    const uploadPassportPhoto = await handleFileUpload(passportPhoto, "passportPhoto");

    if (!uploadTranscript || !uploadPassportData || !uploadPassportPhoto) {
      setAlert({
        visible: true,
        message: "Error uploading files. Please try again..",
        type: "danger"
      });
      setLoading(false);
      // return;
    }else{
      setAlert({
        visible: true,
        message: "File uploaded Successfully",
        type: "success"
      });
      setLoading(false);
    }




    //lookup backend API
    //    protected $allowedFields = [
    //   'first_name', 'last_name', 'email', 'phone', 'date_of_birth', 'address',
    //   'city', 'state', 'zip', 'previous_education', 'gpa', 'nok' 'statement_of_purpose'
    // ];
      // Prepare form data to be sent to the backend
      const data = new FormData();
      data.append('first_name', firstName);
      data.append('last_name', lastName);
      data.append('email', email);
      data.append('phone', phone);

      data.append('date_of_birth', dob);//date_of_birth
      data.append('address', address);//address
      data.append('city', city);//city
      data.append('state', state);//state
      data.append('zip', zip);//zip
      data.append('previous_education', previousEducation);//previous_education

      data.append('gpa', gpa);
      data.append('nok', nextOfKin);
      data.append('statement_of_purpose', statementOfPurpose); 

      data.append('transcript', transcript);
      data.append('passportData', passportData);
      data.append('passportPhoto', passportPhoto);
      // console.log(data);
    try {
       // Access the API URL from the environment variable
      const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

      const response = await fetch(`${apiUrl}/apply/submit`, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        body: data,
      });

      if (response.ok) {
        // If the response is successful
        // const result = await response.json();
        // console.log(result);
        setAlert({
          visible: true,
          message: "Application Submitted Successfully!",
          type: "success"
        });
        setIsSubmitted(true); // Set submission status to true
      } else {
        // If the response is not successful, handle the error
        const errorResult = await response.json();
        // console.error("Error:", errorResult);
        if (errorResult.message && errorResult.message.includes("Duplicate entry")) {
          setAlert({
            visible: true,
            message: "Someone has used that email before. Please try again.",
            type: "danger"
          });
        }else{
          setAlert({
            visible: true,
            message: "Error submitting the application. Please try again.",
            type: "danger"
          });
        }

      }
    } catch (error) {
      // Handle any errors during the fetch request
      console.error("Network Error:", error);
      setAlert({
        visible: true,
        message: "Network error. Please check your connection and try again.",
        type: "danger"
      });
    } finally{
      setLoading(false);
    }
  };

  return (
    <div>
      <div id="application">
        <div className="container">

          <div className="col-md-8">
          {isSubmitted ? (
                <div className="success-message">
                    <h2>Application Submitted Successfully!</h2>
                    <p>Thank you for your application. You can check your email for confirmation.</p>
                </div>
            ) : (
            <div className="row">
              <div className="section-title">
                <h2>Application Form</h2>
                <p>
                  Please fill out the form below to apply for our Nursing  and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="firstName">First Name <span style={{ color: 'red' }}>*</span></label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        className="form-control"
                        placeholder="Enter your first name"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name <span style={{ color: 'red' }}>*</span></label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        className="form-control"
                        placeholder="Enter your last name"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="form-group">
                          <label htmlFor="dob">Date of Birth <span style={{ color: 'red' }}>*</span></label>
                          <input
                              type="date"
                              id="dob"
                              name="dob"
                              className="form-control"
                              placeholder="Enter your date of birth"
                              value = {formData.dob}
                              required
                              onChange={handleChange}
                          />
                      </div>
                  </div>                 
                </div>
                <div className="row">
                  <h3 className="col-md-12">Contact Information</h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="email">Email <span style={{ color: 'red' }}>*</span></label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value = {formData.email}
                        className="form-control"
                        placeholder="Enter your email"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="phone">Phone Number <span style={{ color: 'red' }}>*</span></label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Enter your phone number"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">

                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="nextOfKin">Next of Kin <span style={{ color: 'red' }}>*</span></label>
                      <input
                        type="text"
                        id="nextOfKin"
                        name="nextOfKin"
                        className="form-control"
                        placeholder="Enter the name of your next of kin"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">


                  <div className="col-md-12">
                      <div className="form-group">
                          <label htmlFor="address">Address <span style={{ color: 'red' }}>*</span></label>
                          <input
                              type="text"
                              id="address"
                              name="address"
                              className="form-control"
                              placeholder="Enter your address"
                              required
                              onChange={handleChange}
                          />
                      </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4">
                      <div className="form-group">
                          <label htmlFor="city">City <span style={{ color: 'red' }}>*</span></label>
                          <input
                              type="text"
                              id="city"
                              name="city"
                              className="form-control"
                              placeholder="Enter your city"
                              required
                              onChange={handleChange}
                          />
                      </div>
                  </div>

                  <div className="col-md-4">
                      <div className="form-group">
                          <label htmlFor="state">State <span style={{ color: 'red' }}>*</span></label>
                          <input
                              type="text"
                              id="state"
                              name="state"
                              className="form-control"
                              placeholder="Enter your state"
                              required
                              onChange={handleChange}
                          />
                      </div>
                  </div>

                  <div className="col-md-4">
                      <div className="form-group">
                          <label htmlFor="zip">Zip Code <span style={{ color: 'red' }}>*</span></label>
                          <input
                              type="text"
                              id="zip"
                              name="zip"
                              className="form-control"
                              placeholder="Enter your zip code"
                              required
                              onChange={handleChange}
                          />
                      </div>
                  </div>
                </div>

                <div className="row">
                  <h3 className="col-md-12">Academic Information</h3>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="gpa">GPA <span style={{ color: 'red' }}>*</span></label>
                      <input
                        type="number"
                        id="gpa"
                        name="gpa"
                        className="form-control"
                        placeholder="Enter your GPA"
                        step="0.01"
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                      <div className="form-group">
                          <label htmlFor="previousEducation">Previous Education <span style={{ color: 'red' }}>*</span></label>
                          <input
                              type="text"
                              id="previousEducation"
                              name="previousEducation"
                              className="form-control"
                              placeholder="Enter your previous education"
                              required
                              onChange={handleChange}
                          />
                      </div>
                  </div>
                </div>

                <div className="row">
                  <h3 className="col-md-12">Upload Documents</h3>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="transcript">Transcript <span style={{ color: 'red' }}>*</span></label>
                      <input
                        type="file"
                        id="transcript"
                        name="transcript"
                        className="form-control"
                        onChange={handleFileChange}
                      />
                      <div>
                        {formData.transcript && <img src={URL.createObjectURL(formData.transcript)} alt="Transcript Preview" style={{ width: "100px", height: "100px" }} />}
                        <progress className="col-md-12" value={uploadProgress.transcript} max="100"></progress>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="passportData">International Passport Data <span style={{ color: 'red' }}>*</span></label>
                      <input
                        type="file"
                        id="passportData"
                        name="passportData"
                        className="form-control"
                        onChange={handleFileChange}
                      />
                      <div>
                        {formData.passportData && <img src={URL.createObjectURL(formData.passportData)} alt="Passport Data Preview" style={{ width: "100px", height: "100px" }} />}
                        <progress className="col-md-12" value={uploadProgress.passportData} max="100"></progress>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="passportPhoto">Passport Photograph <span style={{ color: 'red' }}>*</span></label>
                      <input                        
                        type="file"
                        id="passportPhoto"
                        name="passportPhoto"
                        className="form-control"
                        onChange={handleFileChange}
                      />
                      <div>
                        {formData.passportPhoto && <img src={URL.createObjectURL(formData.passportPhoto)} alt="Passport Photo Preview" style={{ width: "100px", height: "100px" }} />}
                        <progress className="col-md-12" value={uploadProgress.passportPhoto} max="100"></progress>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="statementOfPurpose">Statement of Purpose <span style={{ color: 'red' }}>*</span></label>
                  <textarea
                    id="statementOfPurpose"
                    name="statementOfPurpose"
                    className="form-control"
                    rows="4"
                    placeholder="Enter your statement of purpose"
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>
                {alertState.visible && (
                    <div className={`alert alert-${alertState.type} alert-dismissible`} role="alert">
                      {alertState.message}
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>

                    </div>
                )}
                <button type="submit" className="btn btn-custom btn-lg" disabled={loading}>
                  {loading && <ClipLoader size={20} color={"#fff"} />}
                  Submit Application
                </button>

              </form>

            </div>
          )}
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <span className="h3"><i className="fa fa-info"></i> Instructions</span>{" "}
            </div>
            <div className="contact-item">
              <p>
                {props.data ? props.data.instructions : "loading instructions..."}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

