import React, { useState } from 'react';
const menuStyle = {
    menuSection: {
        backgroundColor: '#f8f9fa',
        border: '1px solid #dee2e6',
        marginBottom: '10px',
        padding: '10px',
        borderRadius: '5px'
    },
    menuHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer'
    },
    menuHeaderH5: {
        margin: 0,
        fontSize: '2.5rem',
    },
    plusIcon: {
        fontSize: '3rem',
        border: '1px solid #000', // Border around the plus sign
        padding: '1px 10px 1px 10px', // Padding inside the box
        borderRadius: '2px' // Rounded corners
    }
};
export const FeaturesDropdowns = (props) => {
    const [openSection, setOpenSection] = useState(null);

    const toggleSection = (section) => {
        setOpenSection(openSection === section ? null : section);
    };
  return (
    <div className="container px-4 px-lg-5 pt-5 mt-5 sub-section-title">
        <div className="container mt-5 mb-5">
        <h2 className='display-4 mb-4'>Additional Info</h2>
            <div style={menuStyle.menuSection}>
                <div style={menuStyle.menuHeader}  onClick={() => toggleSection('contactInfo')}>
                    <h5 style={menuStyle.menuHeaderH5}>Contact Info</h5>
                    <span style={menuStyle.plusIcon}>+</span>
                </div>
                <div id="contactInfo" className={`collapse ${openSection === 'contactInfo' ? 'show' : ''}`}>
                    <p>+2348125529621</p>
                    <p>+2348113325455</p>
                    <p>+19092978491</p>
                </div>
            </div>
            <div style={menuStyle.menuSection}>
                <div style={menuStyle.menuHeader}  onClick={() => toggleSection('programDuration')}>
                    <h5 style={menuStyle.menuHeaderH5}>Program Duration</h5>
                    <span style={menuStyle.plusIcon}>+</span>
                </div>
                <div id="programDuration" className={`collapse ${openSection === 'programDuration' ? 'show' : ''}`}>
                    <p>Program duration: Pre Nursing Program - 12 months 
                     </p>
                    <p>
                    Accelerated Nursing Program - 18 months </p>
                </div>
            </div>
            <div style={menuStyle.menuSection}>
                <div style={menuStyle.menuHeader}  onClick={() => toggleSection('upcomingDeadlines')}>
                    <h5 style={menuStyle.menuHeaderH5}>Upcoming Deadlines</h5>
                    <span style={menuStyle.plusIcon}>+</span>
                </div>
                <div id="upcomingDeadlines" className={`collapse ${openSection === 'upcomingDeadlines' ? 'show' : ''}`}>
                    <p>Upcoming deadline would be communicated soon ...</p>
                </div>
            </div>
            <div style={menuStyle.menuSection}>
                <div style={menuStyle.menuHeader}  onClick={() => toggleSection('relatedLinks')}>
                    <h5 style={menuStyle.menuHeaderH5}>Related Links</h5>
                    <span style={menuStyle.plusIcon}>+</span>
                </div>
                <div id="relatedLinks" className={`collapse ${openSection === 'relatedLinks' ? 'show' : ''}`}>

                    <a class="display-4" href="https://www.instagram.com/omoze_nursing_educator?igsh=cjg5MXJrcGg5czdz&utm_source=qr">
                        Instagram                     <i class="fa fa-instagram"></i>
                    </a>
                </div>
            </div>

        </div>
    </div>
  );
};
