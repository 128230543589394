import React from "react";
const introStyle = {
    display: 'table',
    width: '100%',
    height: '60%',
    padding: '090',
    background: 'url(../img/intro-bg.jpg) center center no-repeat',
    backgroundColor: '#e5e5e5',
    WebkitBackgroundSize: 'cover',
    MozBackgroundSize: 'cover',
    backgroundSize: 'cover',
    OBackgroundSize: 'cover',
  };
  
  const overlayStyle = {
    background: 'rgba(255, 255, 255, 0.52)',
    margin: '090',
  };
  
  const overlayTextStyle = {
    background: 'rgba(0, 0, 0, 0.3)',
    fontFamily: '"Raleway", sans-serif',
    color: 'rgba(255, 255, 255, 0.9)',
    fontSize: '80px',
    fontWeight: 700,
    textTransform: 'uppercase',
  };
  
  
  const displayStyle = {
    fontFamily: '"Raleway", sans-serif',
    color: 'rgba(52, 52, 56, 0.9)',
    fontSize: '42px',
    fontWeight: 900,
    textTransform: 'uppercase',
  };
  
export const HeaderJumbo = (props) => {
  return (
    <div className="container px-4 px-lg-5 pt-5">
        <div className="row gx-4 gx-lg-5 align-items-center my-5 pt-5">
            <div className="col-lg-7"><img className="img-fluid rounded mb-4 mb-lg-0" src="../img/intro-bg.jpg" alt="..." /></div>
            <div className="col-lg-5">
                <h1 className="display-4" style={displayStyle}>OMOZE NURSING EDUCATORS </h1>
                <br />
                <h2>{props.data ? props.data.title : "Loading"}</h2>
                <p>{props.data ? props.data.paragraph : "Loading"}</p>
                <br />
                <br />
                <br />
                <a className="btn btn-primary btn-lg display-4" href="/apply"><span className=" display-4">APPLY NOW</span></a>
            </div>
        </div>
        {/* <!-- Call to Action--> */}

        {/* <div className="card text-white bg-secondary my-5 py-4 text-center">
            <div className="card-body"><p className="text-white m-0 h3">Our programs are tailor made</p></div>
        </div> */}
        {/* <!-- Content Row--> */}

    </div>
  );
};
