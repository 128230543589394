import React, { useState, useEffect } from "react";

import { About } from "./components/about";
import { WhoCanApply } from "./components/WhoCanApply";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import { Footer } from "./components/Footer";
import JsonData from "./data/data.json";
// import SmoothScroll from "smooth-scroll";
import { Mission } from "./components/Mission";
import { Partnerships } from "./components/partnerships";
import { NavigationJumbo } from "./components/navigationJumbo";
import { HeaderJumbo } from "./components/headerJumbo";
import { FeaturesJumbo } from "./components/featuresJumbo";
import { WhyChooseUs } from "./components/WhyChooseUs";
import { StyledSection } from "./components/StyledSection";
import { FeaturesDropdowns } from "./components/featuresDropdowns";




// export const scroll = new SmoothScroll('a[href*="#"]', {
//   speed: 1000,
//   speedAsDuration: true,
// });

const HomeJumbo = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <NavigationJumbo />
      <HeaderJumbo data={landingPageData.Header} />


      <About data={landingPageData.About} />
      <FeaturesJumbo data={landingPageData.Features} />
      <FeaturesDropdowns data={landingPageData.Features} />
      <WhyChooseUs data={landingPageData.About} />
      {/* <StyledSection data ={landingPageData.About} /> */}
      <WhoCanApply data={landingPageData.WhoCanApply} />
      <Gallery data={landingPageData.Gallery} />
      <Partnerships />

      <Mission />

      <Contact data={landingPageData.Contact} />
      <Footer />
    </div>
  );
};

export default HomeJumbo;
