import React, { useState } from 'react';

export const FeaturesJumbo = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };
    const toggleCollapse2 = () => {
        setIsOpen2(!isOpen2);
    };
  return (
    <div className="container px-4 px-lg-5 pt-5 mt-5 sub-section-title">

        <div className="row gx-4 gx-lg-5">
            <h2 className='display-4 mb-4'>Our Programs</h2>
            <div className="col-md-6 mb-5">
                <div className="card h-100">
                    <div className="card-body">
                        <h2 className="card-title mb-5">{props.data ? props.data[0].title : "Loading"}</h2>
                        <p className="card-text">{props.data ? props.data[0].text : "Loading"}</p>
                    </div>
                    <button className="btn btn-primary btn-lg" onClick={toggleCollapse}>
                        More Info
                    </button>
                    <div className={`collapse ${isOpen ? 'show' : ''}`}>
                        <div className="card-body">
                                <p>{props.data ? props.data[0].details : "Loading"}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-6 mb-5">
                <div className="card h-100">
                    <div className="card-body">
                        <h2 className="card-title  mb-5">{props.data ? props.data[1].title : "Loading"}</h2>
                        <p className="card-text">{props.data ? props.data[1].text : "Loading"}</p>
                    </div>
                    <button className="btn btn-primary btn-lg" onClick={toggleCollapse2}>
                        More Info
                    </button>
                    <div className={`collapse ${isOpen2 ? 'show' : ''}`}>
                        <div className="card-body">
                                <p>{props.data ? props.data[1].details : "Loading"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
};
