import React from "react";


export const WhoCanApply = (props) => {
  return (
    <div>


      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Who Can Apply</h2>


          </div>


      {/* collapse or accordion */}


          <div className = "row">
            <div className = "col-md-6">
                <a className="" data-bs-toggle="collapse" href="#multiCollapseExample1" role="button" aria-expanded="false" aria-controls="multiCollapseExample1">
                  <div>
                    <div className="row d-flex align-items-center justify-content-center">
                            <i className='fa fa-user-md h3'></i>
                            <div className="service-desc">
                              <h2>Pre-Nursing Diploma (if applicable)</h2>
                              <p >Show More</p>
                            </div>

                      </div>
                  </div>
                </a>


                    <div className="collapse multi-collapse sub-section-collapse" id="multiCollapseExample1">
                      <div className="">

                          <div className="h4">

                              <h3>One-year program for non-health related degree holders.</h3>
                              
                                Candidates who have, at least, a (Second Class Lower) Bachelor’s degree in a non-health related courses but have a credit or distinction in WAEC or other O’Level examinations in biology, chemistry, physics and mathematics can apply into the one year Pre-Nursing diploma. They will spend the one academic year taking university level classes in the following:
                                <br />
                                <br />
                                General Biology with Lab<br />
                                General Chemistry with Lab<br />
                                General Physics with Lab<br />
                                Mathematics (Algebra or Calculus)<br />
                                Human Anatomy with Lab<br />
                                Human Physiology with Lab<br />
                                Microbiology with lab<br />
                                Sociology<br />
                                Psychology<br />




                                Upon successful completion of the one year pre-nursing program, candidate can proceed into the 18 month accelerated nursing degree.


                          </div>

                      </div>
                    </div>

            </div>
            <div className = "col-md-6">
                <a className="" data-bs-toggle="collapse" href="#multiCollapseExample2" role="button" aria-expanded="false" aria-controls="multiCollapseExample2">
                  <div>
                    <div className="row d-flex align-items-center justify-content-center">


                        
                            <i className='fa fa-stethoscope'></i>
                            <div className="service-desc">
                              <h3>B.S. Nursing Degree</h3>
                              <p>Show more</p>
                            
                            </div>

                       
                    </div>
                      
                  </div>
                </a>
                <div className="collapse multi-collapse sub-section-collapse" id="multiCollapseExample2">
                  <div className="h4">
                      <h3>Accelerated 18-month program leading to a B.S. Nursing degree.</h3>
                      To be admitted into any of our affiliate universities, candidate must have, at least, a (Second Class Lower) Bachelor’s degree in the following health-related courses.
                      <br />
                      <br />
                      Public Health<br />
                      Microbiology<br />
                      Anatomy<br />
                      Physiology<br />
                      Biomedical sciences<br />
                      Medical Laboratory science<br />
                      Nutrition<br />
                      Medicine<br />
                      Pharmacy<br />
                      etc<br />

                  
                  </div>
                </div>

            </div>
          </div>
                

        </div>
      </div>







    </div>
  );
};
